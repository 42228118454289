<input type="hidden" hidden class="md:text-center">

<ng-template #lineList let-item>
	<li class="block mb-4">
		<h5 class="text-lg font__title uppercase md:text-{{ item.titlePos }}">{{ item.title }}</h5>
	</li>
	
	@for (subItem of item.list; track $index) {
		<li class="block p-1">
			<p class="text-base font-light">
				@if(subItem.action) {
					<a (click)="subItem.action()" class="cursor-pointer inline-flex flex-wrap items-center">
						@if (subItem.typeIcon && subItem.icon) {
							<i *ngIf="subItem.typeIcon == 'icon'" class="{{ subItem.icon }} mr-1 text-[20px]"></i>
							<img *ngIf="subItem.typeIcon == 'img'" src="{{ subItem.icon }}" class="mr-1" />
						} {{ subItem.name }}
					</a>
				} @else {
					@if(subItem.url) {
						@if (subItem.target) {
							<a href="{{ subItem.url }}" [target]="subItem.target" class="inline-flex flex-wrap items-center">
								@if (subItem.typeIcon && subItem.icon) {
									<i *ngIf="subItem.typeIcon == 'icon'" class="{{ subItem.icon }} mr-1 text-[20px]"></i>
									<img *ngIf="subItem.typeIcon == 'img'" src="{{ subItem.icon }}" class="mr-1" />
								} {{ subItem.name }}
							</a>
						} @else {
							<a routerLink="{{ subItem.url }}" class="inline-flex flex-wrap items-center">
								@if (subItem.typeIcon && subItem.icon) {
									<i *ngIf="subItem.typeIcon == 'icon'" class="{{ subItem.icon }} mr-1 text-[20px]"></i>
									<img *ngIf="subItem.typeIcon == 'img'" src="{{ subItem.icon }}" class="mr-1" />
								} {{ subItem.name }}
							</a>
						}
					} @else {
						<span class="inline-flex flex-wrap items-center cursor-default">
							@if (subItem.typeIcon && subItem.icon) {
								<i *ngIf="subItem.typeIcon == 'icon'" class="{{ subItem.icon }} mr-1 text-[20px]"></i>
								<img *ngIf="subItem.typeIcon == 'img'" src="{{ subItem.icon }}" class="mr-1" />
							} {{ subItem.name }}
						</span>
					}
				}
			</p>
		</li>
	}
</ng-template>

<footer class="sm:px-[3rem] px-4 pt-[3rem] bg-secondary footer__contain">
	@if(xServices.lengthItems(linesImage)) {
		<div class="w-[900px] max-w-full mx-auto pb-[3rem] border-b logos__footer-border">
			@for (lineImage of linesImage; track $index; let last = $last) {
				<input type="hidden" hidden class="hidden justify-center justify-around justify-betwenn pb-4">
				<div class="w-full flex justify-{{ lineImage.justify }} items-center sm:gap-4 gap-1" [ngClass]="{'pb-4': !last}">
					@for (item of lineImage.lines; track $index) {
						@if(item.img) {
							@if (item.url) {
								<a [href]="item.url" target="_blank">
									<img [src]="item.img" [alt]="item.name" [style.width]="item.width">
								</a>
							} @else {
								<img [src]="item.img" [alt]="item.name" [style.width]="item.width">
							}
						}
					}
				</div>
			}
		</div>
	}
	@if (line1 || line2 || line3) {
		<div class="w-[1440px] max-w-full mx-auto pt-4 lg:px-5 px-3 text-center">
			<div class="w-[900px] max-w-full mx-auto pb-[3rem]">
				<nav class="flex flex-wrap justify-between">
					@if (line1) {
						<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
							<ng-container *ngTemplateOutlet="lineList; context: { $implicit: line1 }"></ng-container>
						</ul>
					}
					@if (line2) {
						<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
							<ng-container *ngTemplateOutlet="lineList; context: { $implicit: line2 }"></ng-container>
						</ul>
					}
					@if (line3) {
						<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
							<ng-container *ngTemplateOutlet="lineList; context: { $implicit: line3 }"></ng-container>
						</ul>
					}
				</nav>
			</div>
		</div>
	}
	<div class="w-[1024px] max-w-full mx-auto p-4 lg:px-5 px-3 text-center border-t border-[#737373] copyright__footer-border">
		<p class="font-medium text-sm text-gray-400 copyright__footer">&copy; Copyright {{ year ?? currentYear }} {{ title ?? 'Solunes Digital' }} | Todos los derechos reservados.</p>
	</div>
</footer>