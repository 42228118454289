import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import Swal from 'sweetalert2';
import { NotificationService } from '../../../../../../services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { Data, XtrasService } from '../../../../../../services/xtras.service';
import { AuthService, UserType } from '../../../../../../services/auth.service';
import { UserModel } from '../../../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../../../components/dropdown-menu/drowdown-menu.component';
import { getEnvironment } from '../../../../../../environment/environment';
declare var $: any;

@Component({
  selector: 'app-footer-app-1',
  templateUrl: './footer-app.component.html',
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
  ]
})
export class FooterApp1Component implements OnInit {


  back_location = null;

  playstore?: string;
  appstore?: string;

  env = getEnvironment();

  constructor(
    private toast: NotificationService,
    public xServices: XtrasService,
    public authService: AuthService,
    public userModel: UserModel,
    private router: Router,
  ) {
    if(this.env.type == 'club-bolivar') {
      this.playstore = 'https://play.google.com/store/apps/details?id=com.bolivar.app';
      this.appstore = 'https://apps.apple.com/bo/app/club-bol%C3%ADvar/id6450942092';
    } else if(this.env.type == 'club-alianzalima') {
      this.playstore = 'https://play.google.com/store/apps/details?id=com.peru.alianzalimaperu'
    } else if(this.env.type == 'club-colocolo') {
      this.playstore = 'https://play.google.com/store/apps/details?id=cl.colocolo.app';
    } else if(this.env.type == 'udechile') {
      this.playstore = 'https://play.google.com/';
      this.appstore = 'https://apps.apple.com/';
    } else if(this.env.type == 'ucatolica') {
      this.playstore = 'https://play.google.com/store/apps/details?id=io.kodular.todogold04.Universidad_Catolica_de_Chile_mi_Pasion';
      this.appstore = 'https://apps.apple.com/do/app/la-catolica/id1618491110';
    }
  }

  ngOnInit() {
  }

  logout(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: '¿Desea cerrar su Sesión?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result: any) => {
      if(result.value) {
        this.doLogout();
      } else if(result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  doLogout() {
    this.authService.logout();
    this.toast.success('Sesión cerrada', 'Cerraste tu sesión exitosamente', 4000);
  }

  ngOnDestroy(): void {
  }

  closeMenu() {
    // Todo: Añadir servicio en el backend para realizar esta acción
    // const subscr = this.http.getRequest(SettingsProvider.getUrl(`mark-notifications-as-read`)).subscribe((result: any) => {
    //   if(result['status']) {
    //     this.load.dismiss();
    //     this.authService.getUserByToken().subscribe();
    //   } else {
    //     this.load.dismiss();
    //     this.toast.error('Error al leer las notificaciones', result['message'], 4000);
    //   }
    // }, () => {
    //   this.load.dismiss();
    //   this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    //   // this.errorPage();
    // });
    // this.unsubscribe.push(subscr);
  }

  toggleSidebarDesk() {
    $('body').toggleClass('hide__menu');
  }

  toLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  toRegister() {
    this.router.navigateByUrl('/auth/register');
  }
}
